import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import ServiceLogo from "../../components/ServiceLogo/ServiceLogo";
import Umbrella from "../../components/Umbrella/Umbrella";
import UmbrellaContent from "../../components/UmbrellaContent/UmbrellaContent";
import { ExternalLinkIcon, MessageIcon } from "../../icons";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const SolutionDetailPage = ({ data: { page } }) => (
  <MainLayout
    header={(
      <Header
        headline={page.headline}
        bgImageUrl={`linear-gradient(0deg, #12121E 0.32%, rgba(18, 18, 30, 0.18) 100%), url(${page.headerimage?.asset?.url})`}
        cta={page.showCTA && (
          <Button
            size="small"
            title={page.ctaButton?.title}
            color="white"
            variant="primary"
            href={page.ctaButton?.external}
          >
            {page.ctaButton?.title} <ExternalLinkIcon />
          </Button>
        )}
        variant="solution"
        headlineClass="text-h2"
        secondaryHeadline={page.secondaryHeadline}
        text={<BlockContent blocks={page._rawDescription} />}
        logo={page.icon && (
          <SanityImage
            {...page.icon}
            alt={page.title}
          />
        )}
        umbrella={(
          <div className="grid grid-cols-12 lg:gap-x-30">
            <div className="col-span-12 lg:col-span-8">
              <UmbrellaContent umbrella={<Umbrella label="Delivered Services" color="white" />}>
                {page.services?.map((node) => (
                  <ServiceLogo
                    key={node.variant}
                    variant={node.variant}
                    size="small"
                  />
                ))}
              </UmbrellaContent>
            </div>
          </div>
        )}
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);
export const query = graphql`
  query ($id: String!) {
    page: sanitySolutionDetail(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      services {
        variant
      }
      title
      slug {
        current
      }
      headerimage {
        asset {
          url
        }
      }
      icon {
        ...ImageWithPreview
      }
      variant
      headline
      secondaryHeadline
      showCTA
      ctaButton {
        variant
        size
        color
        title
        external
        internalLink {
          ... on SanitySolutionDetail {
            id
            slug {
              current
            }
          }
          ... on SanityServiceDetail {
            id
            slug {
              current
            }
          }
          ... on SanityLegalDocument {
            _type
            id
            slug {
              current
            }
          }
          ... on SanityService {
            id
            name
            _type
          }
        }
      }
      _rawDescription
      contentElements {
        ... on SanitySectionHeader {
          _key
          _type
          descriptionText
          headline
          color
          sectionLabel
          showCTA
          button {
            variant
            size
            color
            title
            icon
            internalLink {
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityServiceOverview {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionOverview {
                id
                slug {
                  current
                }
              }
              ... on SanityLegalDocument {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                variant
              }
            }
          }
        }
        ... on SanityServiceCardList {
          _key
          _type
          serviceCardList {
            background
            _rawDescription
            label
            variant
            button {
              variant
              size
              color
              title
              icon
              internalLink {
                ... on SanityLegalDocument {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  variant
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
        ... on SanitySolutionCardList {
          _key
          _type
          showAs
          cardSize
          imagePosition
          solutionCardList {
            headline
            label
            background
            showButton
            image {
              ...ImageWithPreview
            }
            logo {
              ...ImageWithPreview
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanitySolutionDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
          }
        }
        ... on SanityOfficeCardList {
          _key
          _type
          officeCardList {
            areaTitle
            headline
            _rawAddress
            icon {
              _key
              _type
              _rawAsset
              _rawHotspot
              _rawCrop
            }
            services {
              name
              variant
              lead {
                image {
                  _key
                  _type
                  _rawAsset
                  _rawHotspot
                  _rawCrop
                }
                mail
                name
                telephone
                id
              }
            }
          }
        }
        ... on SanityContentCardList {
          _key
          _type
          variant
          sectionID
          initiallyOpen
          actAsAccordion
          contentCardList {
            headline
            variant
            _rawDescription
            image {
              ...ImageWithPreview
            }
            icon
            showRelatedLinks
            showPrimaryCTA
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityService {
                _type
                id
                name
                variant
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityCtaCardList {
          _key
          _type
          title
          sectionID
          variant
          ctaCards {
            variant
            _key
            _type
            aspect
            image {
            asset {
                url
              }
            }
            label
            headline
            icon
            _rawDescription
          }
        }
        ... on SanityContentCardGrid {
          _key
          _type
          variant
          sectionID
          contentCards {
            _id
            headline
            variant
            _rawDescription
            icon
            showRelatedLinks
            showPrimaryCTA
            image {
              ...ImageWithPreview
            }
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityService {
                _type
                id
                name
                variant
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityCTACard {
          _key
          _type
          variant
          image {
          asset {
              url
            }
          }
          label
          headline
          icon
          _rawDescription
        }
        ... on SanityContentModuleEmbed {
          _key
          _type
          headline
          overline {
            label
            variant
          }
          overlineIcon {
            ...ImageWithPreview
          }
          icon
          color
          label
          position
          image {
            ...ImageWithPreview
          }
          button {
            variant
            size
            color
            title
            internalLink {
              ... on SanityLegalDocument {
                _type
                id
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                _type
              }
            }
          }
          
          relatedLinks {
            ... on SanityService {
              id
              name
              _type
            }
            ... on SanityHomepage {
              id
              slug {
                current
              }
              title
            }
            ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
            ... on SanityLegalDocument {
              id
              title
              slug {
                current
              }
            }
          }
          _rawDescription
        }
        ... on SanityContentModuleText {
          _key
          _type
          headline
          overline {
            label
            variant
          }
          overlineIcon {
            ...ImageWithPreview
          }
          color
          label
          position
          relatedLinks {
            ... on SanityService {
              id
              name
              _type
            }
            ... on SanityHomepage {
              id
              slug {
                current
              }
              title
            }
            ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
            ... on SanityLegalDocument {
              id
              title
              slug {
                current
              }
            }
          }
          _rawDescription
        }
        ... on SanityContentSection {
          _key
          _type 
          color
          contentSections {
            _type
            headline
            overline {
              label
              variant
            }
            variant
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
            _rawDescription
            label
            image {
              ...ImageWithPreview
            }
            overlineIcon {
              ...ImageWithPreview
            }
            position
            showRelatedLinks
            relatedLinks {
              ... on SanityService {
                id
                name
                detailpage {
                  id
                  title
                  slug {
                    current
                  }
                }
                _type
              }
              ... on SanityServiceDetail {
                id
                title
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityHomepage {
                id
                slug {
                  current
                }
                title
              }
              ... on SanityLegalDocument {
                id
                title
                slug {
                  current
                }
              }
            }
            umbrellaLabel
            variant
          }
        }
      }
    }
 }
`;

SolutionDetailPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default SolutionDetailPage;
